<template>
  <div class="about">
    <v-row dense>
      <v-col align="center">
        <h1>About Us</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>

        <v-img :src="require('@/assets/logo-full.png')" max-width="300" class="mx-auto"></v-img>
        
        <h3>Our Mission</h3>
        <p>Parlay Poker is the first integrated experience between the world of sports betting and the game of poker.  We strive to bring these communities together to enjoy our game and to fully experience entertainment on both sides of the aisle.</p>

        <h3>Our Product</h3>
        <p>Parlay Poker is a patent-pending solution where users create a sports betting parlay wager that correlates to a poker hand.  Each wager of a parlay bet is called a "leg".  A three-leg parlay correlates to a 3-Card Poker hand.  A five-leg parlay correlates to a 5-Card Poker hand.  The game payouts are associated to the hand rank that the player creates.</p>

        <p>A traditional poker playing card (2 through Ace) and suit (Spades, Clubs, Diamonds, and Hearts) are assigned to odds of sporting event markets using our proprietary algorithm.  The current betting markets supported are moneyline (head-to-head), spreads, and totals (over/under).  Future features will include individual player proposition (prop) bets and other events or formats.</p>

        <p><b>Parlay Poker is strictly for amusement and demonstrative purposes only.</b> The public beta does not offer real money gambling or an opportunity to win real money or real-world prizes.  No actual money or anything of value can be won playing the games and services offered through the service, and no actual money is required to play. Although a wallet amount exists, this is not real money and will not change and is used to enable certain features of the game. The displayed sports teams, schedules, moneyline odds, spread odds, total odds, results, scores, etc. are not based on real data or outcomes.  The outcomes are for demonstrative purposes only.</p>

        <p>User registration is required to track player activities and no data will be used or shared according to our <a href="https://beta.parlaypoker.com/privacy">Privacy Policy.</a></p>

        <h3>Web Platform</h3>
        <p>The Web Platform is the current version of Parlay Poker.  It is a HTML developed application that displays all the features and views of the platform.  For clients, it can be played as a standalone re-branded website or integrated via IFrame or similar methods.</p>

        <h3>API Platform</h3>
        <p>The API Platform is a RESTful application where we offer the Card Assignment API and other APIs to clients as a standalone service.  Consumers of the API will be able to incorporate card assignments and other features to their own odds data platform(s) or online sports book operations.</p>

        <h3>Engage Us</h3>
        <p>For licensing, integration, or sales inquiries please e-mail at: <a href="mailto:sales@parlaypoker.com">sales@parlaypoker.com</a> or use the contact us page.</p>

        <b class="mx-auto"><a href="https://beta.parlaypoker.com/register">Sign-up</a> or <a href="https://beta.parlaypoker.com/login">Login today!</a></b>

      </v-col>
    </v-row>
  </div>
</template>

<script>

  export default {
    name: "About.vue",
    data() {
      return {

      };
    },
    methods: {

    },
  };
</script>

<style lang="scss" scoped>
  .about{
  }
</style>
